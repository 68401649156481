import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { COLORS } from '../../utils/colors';
import { Loader } from '../Loader/Loader';
import { emptyJobInfo, IJobInfo, IPreHireReq } from '../../store/employmentModule/employmentModal';
import { IClientScheduledJob } from './ClientsScheduledInterviews';
import { jobOfferStatusEnums } from './EmploymentEnum';
import {
  getJobById,
  removeClientsOfferAcceptedEntry,
  setClientCompleteAndHired,
} from '../../services/employmentModule.service';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import dayjs from 'dayjs';

const PreHireRequirements = (props: any) => {
  const { clientInfo } = props;
  const [jobInfo, setJobInfo] = useState<IJobInfo>(emptyJobInfo);
  const [loading, setLoading] = useState<boolean>(false);
  const [hiredLoading, setHiredLoading] = useState<boolean>(false);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [selectedPreHireReqs, setSelectedPreHireReqs] = useState<IPreHireReq[]>([]);
  const [pinccCostCovered, setPinccCostCovered] = useState<string>('');

  const handlePreHireReqToggle = (option: IPreHireReq) => {
    const currentIndex = selectedPreHireReqs.findIndex((selectedPreHireReqs) => selectedPreHireReqs.id === option.id);
    const newSelectedOptions = [...selectedPreHireReqs];

    if (currentIndex === -1) {
      newSelectedOptions.push(option);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }

    setSelectedPreHireReqs(newSelectedOptions);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPinccCostCovered(event.target.value);
  };

  const getJobInfo = async (jobId: string) => {
    try {
      if (jobId) {
        setLoading(true);
        const unsubscribe = await getJobById(jobId, (data: any) => {
          setJobInfo(data);
          setLoading(false);
        });
        return () => {
          unsubscribe();
        };
      }
    } catch (err: any) {
      setLoading(false);
      // error handling
    }
  };

  useEffect(() => {
    const jobId = clientInfo?.scheduledJobInterview?.find(
      (item: IClientScheduledJob) => item?.offerStatus === jobOfferStatusEnums.OFFER_ACCEPTED
    )?.jobId;
    getJobInfo(jobId);
    // eslint-disable-next-line
  }, [clientInfo]);

  const handleHiredButtonClick = async () => {
    try {
      setHiredLoading(true);
      const startAt = dayjs().toISOString();
      await setClientCompleteAndHired(clientInfo?.id, jobInfo.id, selectedPreHireReqs, pinccCostCovered, startAt);
      setHiredLoading(false);
    } catch (error) {
      setHiredLoading(false);
      // catch error
    }
  };

  const handleCancelButtonClick = async () => {
    try {
      setCancelLoading(true);
      await removeClientsOfferAcceptedEntry(clientInfo?.id, jobInfo.id);
      setCancelLoading(false);
    } catch (error) {
      setCancelLoading(false);
      // catch error
    }
  };

  return (
    <Box sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'} p={2} display={'block'}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant='h6'>Pre-Hire Requirements</Typography>
      </Box>
      <Box py={1}>
        <Divider variant='fullWidth' light={true} />
      </Box>
      <Box sx={{ p: 1, my: 1 }}>
        {loading ? (
          <Loader loaderBoxStyle={{ height: '30vh' }} />
        ) : (
          <Box sx={{ border: '1px solid grey', p: 1, mb: 2 }}>
            <List>
              <ListItem disablePadding key={'Title'}>
                <ListItemText
                  primary={'Title:'}
                  secondary={jobInfo.title}
                  primaryTypographyProps={styles.listItemPrimaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>
              <ListItem disablePadding key={'Employer'}>
                <ListItemText
                  primary={'Employer:'}
                  secondary={jobInfo.employer}
                  primaryTypographyProps={styles.listItemPrimaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>
              <ListItem disablePadding key={'Job Type'}>
                <ListItemText
                  primary={'Job Type:'}
                  secondary={jobInfo.type}
                  primaryTypographyProps={styles.listItemPrimaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>
            </List>
            {jobInfo?.preHireRequirements && (
              <>
                <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                  Pre-Hire Requirements
                </Typography>
                <FormControl>
                  <List dense sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    {jobInfo?.preHireRequirements.map((option: IPreHireReq) => {
                      const labelId = `checkbox-list-secondary-label-${option.id}`;
                      const isChecked = selectedPreHireReqs.some(
                        (selectedPreHireReqs) => selectedPreHireReqs.id === option.id
                      );

                      return (
                        <ListItem key={option.id} disablePadding sx={{ paddingTop: 0, paddingBottom: 0 }}>
                          <Checkbox
                            size='small'
                            onChange={() => handlePreHireReqToggle(option)}
                            checked={isChecked}
                            sx={{ alignSelf: 'flex-start' }}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                          <ListItemText
                            id={labelId}
                            primary={option.value}
                            sx={{ fontSize: '16px', fontStyle: 'italic', marginTop: 0, marginBottom: 0 }}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </FormControl>
              </>
            )}
            {jobInfo?.preHireRequirements?.some((item) => item.id === '5') ? (
              <Box mt={1}>
                <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                  Did PINCC cover the cost?
                </Typography>
                <RadioGroup row value={pinccCostCovered} onChange={handleRadioChange}>
                  <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                  <FormControlLabel value='no' control={<Radio />} label='No' />
                </RadioGroup>
              </Box>
            ) : null}
            <Box py={1}>
              <Divider variant='fullWidth' light={true} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
              <LoadingButton
                onClick={() => handleHiredButtonClick()}
                label='Complete and Hired'
                loading={hiredLoading}
                disabled={false}
                styles={{
                  textTransform: 'Capitalize',
                }}
                size='small'
                type='submit'
                variant='outlined'
                color='success'
                startIcon={<CheckCircleOutline />}
              />
              <LoadingButton
                onClick={() => handleCancelButtonClick()}
                label='Cancel'
                styles={{
                  textTransform: 'Capitalize',
                  mt: { xs: 2, md: 0 },
                }}
                loading={cancelLoading}
                disabled={false}
                size='small'
                type='submit'
                variant='outlined'
                color='error'
                startIcon={<CancelOutlined />}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PreHireRequirements;

const styles = {
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemPrimaryText: {
    fontWeight: 'bold',
    paddingRight: 2,
    alignSelf: 'flex-start',
  },
  jobTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  jobDescription: {
    fontSize: 16,
  },
};
