import { toast } from 'react-toastify';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebaseApp';
import { storage } from '../config/firebaseApp';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { doc, getDocs, getDoc, collection, query, where, Timestamp, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebaseApp';
import { ClientStatus } from '../utils/clientStatus';
import { ClientMapStatus } from '../utils/clientMapStatus';
import { getMultipleUserInfoByUserIds, getUserFullNameById } from './user.service';
import { getClientMapDataByMultipleClientId } from './programTrackClientMap.service';
import { getMultipleProgramTracksById } from './programTrack.service';
import { v4 as uuidv4 } from 'uuid';
import { shouldShowButton } from '../pages/admin/dashboard/dashboardAccessControl';
import { UserRolesEnum } from '../utils/rolesEnum';
import { UserModel } from '../store/user/userModel';
import { convertTimeStampToDate } from '../utils/dateTime';
import { ClientAccount } from '../utils/clientAccount';
import { userAccountStatus } from '../utils/userUtils';
import { IClientJRTComplete } from '../store/employmentModule/workshopModal';
import { ISortModel } from '../utils/common';
import { getMultipleCareerPathInfoByIds } from './careerPath.service';
import {
  activeStatusOptions,
  educationOptions,
  empStatusOptions,
  employmentTypes,
  ethnicityOptions,
  genderOptions,
  highestDLOptions,
  publicAssistanceOptions,
  referralSourceOptions,
  statusOptions,
} from '../utils/filterOptions';

interface IInfoSession {
  createdBy: string;
  description: string;
  location: string;
  title: string;
  dateTime: number;
}

export interface IUser {
  id?: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  createdBy?: string;
  createdAt?: any;
}

export interface IProspectiveClientModel extends IUser {
  phoneNumber?: string;
  infoSessionId?: string;
  programTrack?: string;
  infoSessionData?: IInfoSession;
  interestedIn?: string;
  status?: string;
}

export interface ClientModel extends IUser {
  address1: string;
  address2: string;
  mobile: string;
  state: string;
  homeNumber: string;
  city: string;
  zip: string;
  workNumber: string;
  dob: string;
  ssn: string;
  gender: string;
  ethnic: string;
  employmentStatus: string;
  educationalBackground: string;
  urls: Array<string>;
  notes: INote[] | Array<object>;
  status?: string;
  id?: string;
  assignedTo?: string;
  phoneNumber?: string;
  isInsured: string;
  caseNumber: string;
  publicAssistance: string[];
  publicAssistanceOther: string;
  referralSource: string;
  refCommunityOrg: string;
  refSourceEntryDate?: Timestamp;
  interestedIn?: string;
  deleteCaseNumber: string;
  ethnicOther: string;
  highestDL: string;
}

export interface ICommonClient extends ClientModel, IProspectiveClientModel {}

export interface ICLientMetaInfo {
  totalRecords: number;
  currentPage: number;
  clients: Array<ICommonClient>;
  totalPages: number;
}

export interface IResponse {
  success: boolean;
  message: string;
}

export interface IData {
  id: string;
  fourDigitSSN: string;
  dob: Date;
}
export interface IDocumentData {
  name: string;
  file: Object;
  uploadedBy: string;
  role: string;
}

export interface INote {
  category?: string;
  title: string;
  description: string;
  createdBy: string;
  role: string;
  clientId?: string;
  createdAt?: Timestamp;
  noteId?: string;
}

export interface IEditNote {
  category?: string;
  title: string;
  description: string;
  clientId: string;
  noteId: string;
}

export interface ICancelIndividualEnrollment {
  id: string;
  programTrackId: string;
}

export interface IResendInvitationMail {
  firstName: string;
  email: string;
  infoSessionIds: string[];
}

export interface ISendTextMessage {
  ids: string[];
  text: string;
}

export interface IClientEmplInfo {
  id: string;
  employmentStatus: string;
  employmentType: string;
}

const mapIdsToValues = (ids: string[], options: { id: string; value: string }[]) => {
  return ids.map((id) => options.find((option) => option.id === id)?.value || '');
};

export const updateClientInfo = async (client: ClientModel, clientId: string) => {
  try {
    const payload = { ...client, id: clientId };
    const response: any = await httpsCallable(functions, 'editClientInfo')(payload);
    if (response.data.success) {
      toast.success('Client Info Updated Successfully!');
    }
  } catch (error: any) {
    toast.error('Error Updating Client Info!');
  }
};

export const addNewClient = async (client: ClientModel, isSelfCreated: boolean, user?: UserModel) => {
  let clientInfo = { ...client };
  if (!isSelfCreated && user) {
    if (client.notes.length > 0) {
      client.notes[0] = { ...client.notes[0], createdBy: user?.id || '', role: user?.role || '', noteId: uuidv4() };
      clientInfo.notes = client.notes;
    } else {
      clientInfo.notes = [];
    }
  }

  const response: any = await httpsCallable(functions, 'createNewClient')(clientInfo);

  return response.data;
};

export const convertToClient = async (data: IData) => {
  const response = await httpsCallable(functions, 'convertProspectiveClient')(data);
  return response.data as Promise<IResponse>;
};

export const resendInvitationMailToPC = async (data: IResendInvitationMail, userRole: string) => {
  const hasPermission = shouldShowButton('resendInvitationMailToPC', userRole);
  if (!hasPermission) {
    throw new Error('You do not have permission to resend mail to Prospective Client');
  }
  const response = await httpsCallable(functions, 'resendInvitationEmailToPC')(data);

  return response.data as Promise<IResponse>;
};

export const listClients = async (page: number, clientStatuses?: Array<String>, is154ECompleted?: boolean) => {
  let params: any = {
    page,
  };
  if (clientStatuses !== undefined) params['clientStatuses'] = clientStatuses;
  if (is154ECompleted !== undefined) params['is154ECompleted'] = is154ECompleted;
  const response: any = await httpsCallable(functions, 'listClients')(params);

  return response.data as Promise<IResponse & { data: ICLientMetaInfo }>;
};

export const getProgramTrackName = (programTrackData: any[]): string => {
  let ptName = '';

  ptName =
    programTrackData.find(
      (ptData) => ptData.status === ClientMapStatus.ACTIVE || ptData.status === ClientMapStatus.PRE_COMPLETE
    )?.programTrack || '';

  if (!ptName) {
    if (programTrackData.some((ptData) => ptData.status === ClientMapStatus.REVIEW)) {
      const ptNameArr: string[] = [];
      for (const ptData of programTrackData) {
        if (ptData.status === ClientMapStatus.REVIEW) {
          ptNameArr.push(ptData.programTrack);
        }
      }
      ptName = ptNameArr.join(' ');
    }
  }

  if (!ptName) {
    ptName = programTrackData.find((ptData) => ptData.status === ClientMapStatus.COMPLETE)?.programTrack || '';
  }

  if (!ptName) {
    ptName = programTrackData.find((ptData) => ptData.status === ClientMapStatus.DROP_OUT)?.programTrack || '';
  }

  return ptName;
};

export const fetchClientList = async (user: UserModel) => {
  try {
    const clientListSnapshot = await getDocs(collection(db, 'clientList'));
    if (clientListSnapshot.empty) {
      return [];
    }

    const clientList = clientListSnapshot.docs.map((doc) => {
      const programTrackData = doc.data()?.programTrackData || [];
      const programTrackName = getProgramTrackName(programTrackData);

      return {
        id: doc.id,
        ...doc.data(),
        programTrackName,
      };
    });

    if (user.role === UserRolesEnum.TRAINER) {
      const trackClientMapQuery = query(collection(db, 'programTrackClientMap'), where('trainerId', '==', user.id));
      const trackClientMapSnapshot = await getDocs(trackClientMapQuery);

      if (trackClientMapSnapshot.empty) {
        return [];
      }

      const clientIds = new Set(trackClientMapSnapshot.docs.map((doc) => doc.data().clientId));

      return clientList.filter((client) => clientIds.has(client.id));
    } else {
      return clientList;
    }
  } catch (err) {
    toast.error('Error Retrieving Client Data!');
    return [];
  }
};

export const listClientByQuery = async (
  page: number,
  filterStatus: string,
  sortModel: ISortModel[],
  user: UserModel
): Promise<ICLientMetaInfo | undefined> => {
  try {
    const clientList: any = await fetchClientList(user);

    const filteredClient =
      filterStatus !== 'all'
        ? clientList.filter((item: any) => item.clientStatus.toLowerCase() === filterStatus.toLowerCase())
        : clientList;

    if (filteredClient.length === 0) {
      return {
        totalRecords: 0,
        currentPage: page,
        clients: [],
        totalPages: 0,
      };
    }

    if (sortModel.length > 0) {
      const field = sortModel[0].field;
      const sort = sortModel[0].sort;

      if (field === 'name') {
        filteredClient.sort((a: any, b: any) => {
          if (sort === 'asc') {
            return a.name.localeCompare(b.name);
          } else if (sort === 'desc') {
            return b.name.localeCompare(a.name);
          }
        });
      } else if (field === 'clientStatus') {
        filteredClient.sort((a: any, b: any) => {
          if (sort === 'asc') {
            return a.clientStatus.localeCompare(b.clientStatus);
          } else if (sort === 'desc') {
            return b.clientStatus.localeCompare(a.clientStatus);
          }
        });
      } else if (field === 'navigator') {
        filteredClient.sort((a: any, b: any) => {
          if (a.navigator.length < 1 && b.navigator.length < 1) {
            return 0;
          } else if (a.navigator.length < 1) {
            return 1;
          } else if (b.navigator.length < 1) {
            return -1;
          } else {
            if (sort === 'asc') {
              return a.navigator[0].name.localeCompare(b.navigator[0].name);
            } else if (sort === 'desc') {
              return b.navigator[0].name.localeCompare(a.navigator[0].name);
            }
          }
        });
      } else if (field === 'programTrackName') {
        filteredClient.sort((a: any, b: any) => {
          if (!a.programTrackName && !b.programTrackName) {
            return 0;
          } else if (!a.programTrackName) {
            return 1;
          } else if (!b.programTrackName) {
            return -1;
          } else {
            if (sort === 'asc') {
              return a.programTrackName.localeCompare(b.programTrackName);
            } else if (sort === 'desc') {
              return b.programTrackName.localeCompare(a.programTrackName);
            }
          }
        });
      } else if (field === 'interestedIn') {
        filteredClient.sort((a: any, b: any) => {
          if (a.interestedIn.length < 1 && b.interestedIn.length < 1) {
            return 0;
          } else if (a.interestedIn.length < 1) {
            return 1;
          } else if (b.interestedIn.length < 1) {
            return -1;
          } else {
            if (sort === 'asc') {
              return a.interestedIn[0].name.localeCompare(b.interestedIn[0].name);
            } else if (sort === 'desc') {
              return b.interestedIn[0].name.localeCompare(a.interestedIn[0].name);
            }
          }
        });
      }
    } else {
      filteredClient.sort((a: any, b: any) => {
        const dateA = a.createdAt ? new Date(a.createdAt) : null;
        const dateB = b.createdAt ? new Date(b.createdAt) : null;

        if (dateA && dateB) {
          return dateB.getTime() - dateA.getTime();
        } else if (dateB) {
          return 1; // Place documents without 'createdAt' field after those with 'createdAt'
        } else if (dateA) {
          return -1; // Place documents without 'createdAt' field before those with 'createdAt'
        } else {
          return 0; // Both documents don't have 'createdAt' field, no preference in order
        }
      });
    }

    const pageSize = 10;
    const offset = (page - 1) * pageSize;
    const totalPages = Math.ceil(filteredClient.length / pageSize);
    const limitedClients = filteredClient.slice(offset, offset + pageSize);

    return {
      totalRecords: filteredClient.length,
      currentPage: page,
      clients: limitedClients,
      totalPages: totalPages,
    };
  } catch (err: any) {
    toast.error('Error Retrieving Client Data!');
  }
};

export const searchClientsByQuery = async (searchQuery: string, searchQueryType: string, user: UserModel) => {
  try {
    if (!searchQuery) {
      throw new Error('Required arguments are either null or undefined');
    }
    if (searchQuery.includes('@')) {
      searchQuery = searchQuery.split('@')[0];
    }
    const response: any = await httpsCallable(
      functions,
      'searchClientByName'
    )({ query: searchQuery, type: searchQueryType });
    const clientData = response.data.data;
    const clientIds: any = [];
    let clientList = [];

    if (clientData.length > 0) {
      for (const data of clientData) {
        clientIds.push(data.id);
      }

      if (user.role === UserRolesEnum.TRAINER) {
        const ptcmData = await getClientMapDataByMultipleClientId(clientIds);

        const filteredPTCM = ptcmData.filter((data: any) => data.trainerId === user.id);
        const clientIdsByTrainer = filteredPTCM.map((data: any) => data.clientId);

        clientList = await getMultipleClientListDataByIds(clientIdsByTrainer);
      } else {
        clientList = await getMultipleClientListDataByIds(clientIds);
      }

      return clientList;
    } else {
      return [];
    }
  } catch (error) {
    toast.error('Error Searching Client!');
  }
};

export const getMultipleClientByIds = async (clientIds: string[]) => {
  try {
    if (clientIds?.length > 0) {
      const clientsRef = collection(db, 'clients');
      const querySnapshot = await getDocs(query(clientsRef));
      const documents = querySnapshot.docs.filter(
        (doc) => clientIds.includes(doc.id) && doc.data().account !== ClientAccount.DELETED
      );
      const clientList: any = [];
      documents.forEach((doc) => {
        clientList.push({ id: doc.id, ...doc.data() });
      });

      return clientList;
    }
    return [];
  } catch (err: any) {
    toast.error('Error Retrieving Clients!');
    return [];
  }
};

export const getMultipleClientListDataByIds = async (clientIds: string[]) => {
  try {
    if (clientIds.length > 0) {
      const clientListRef = collection(db, 'clientList');
      const querySnapshot = await getDocs(query(clientListRef));
      const documents = querySnapshot.docs.filter(
        (doc) => clientIds.includes(doc.id) && doc.data().account !== ClientAccount.DELETED
      );
      const clientList: any = [];
      documents.forEach((doc) => {
        clientList.push({ id: doc.id, ...doc.data() });
      });

      return clientList;
    }
    return [];
  } catch (err: any) {
    toast.error('Error Retrieving Clients!');
    return [];
  }
};

export const searchClients = async (
  page: number,
  searchQuery: string,
  searchQueryType: string,
  filterStatus: string,
  user: UserModel
) => {
  try {
    const clients: any = await searchClientsByQuery(searchQuery, searchQueryType, user);
    let filteredClients: any[] = [];

    if (clients.length > 0) {
      if (filterStatus !== 'all') {
        filteredClients = clients.filter((item: any) => item.status === filterStatus);
      } else {
        filteredClients = [...clients];
      }

      const clientList = filteredClients.map((item) => {
        const programTrackData = item?.programTrackData || [];
        const programTrackName = getProgramTrackName(programTrackData);

        return {
          ...item,
          programTrackName,
        };
      });

      const pageSize = 10;
      const offset = (page - 1) * pageSize;
      const totalPages = Math.ceil(clientList.length / pageSize);
      const paginatedClients = clientList.slice(offset, offset + pageSize);

      const result = {
        totalRecords: clientList.length,
        currentPage: page,
        clients: paginatedClients,
        totalPages: totalPages,
      };
      return result;
    } else {
      return {
        totalRecords: 0,
        currentPage: 1,
        clients: [],
        totalPages: 0,
      };
    }
  } catch (error) {
    toast.error('Error Searching Clients!');
  }
};

export const uploadFiles = async (files: any) => {
  try {
    if (!files) {
      throw new Error();
    }

    const urls: any = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(storage, `files/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      urls.push(downloadURL);
    }
    return urls;
  } catch (error: any) {
    toast.error('Error Uploading Files!');
  }
};

const queryFor154ELetter = async (clientId: string) => {
  const collectionRef = collection(db, 'programTrackClientMap');
  const q = query(
    collectionRef,
    where('clientId', '==', clientId),
    where('status', 'in', [ClientMapStatus.ACTIVE, ClientMapStatus.REVIEW, ClientMapStatus.PRE_COMPLETE])
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
};

export const upload154ELetter = async (letter: any, clientId: any) => {
  try {
    if (!letter || !clientId) {
      throw new Error();
    }

    const querySnapshot = await queryFor154ELetter(clientId);

    if (!querySnapshot.empty) {
      const ptcmDoc = querySnapshot.docs[0].data();
      if (ptcmDoc.status === ClientMapStatus.ACTIVE || ptcmDoc.status === ClientMapStatus.REVIEW) {
        const storageRef = ref(storage, `letters_145E/${letter.name}`);
        const snapshot = await uploadBytes(storageRef, letter);

        if (snapshot) {
          const downloadURL = await getDownloadURL(snapshot.ref);
          const payload = { id: querySnapshot.docs[0].id, url: downloadURL, name: letter.name };
          const response: any = await httpsCallable(functions, 'set154ELetterUrl')(payload);

          if (response.data.success) {
            toast.success('File Uploaded Successfully!');
            return response.data.data.letter_154E.url;
          }
        } else {
          throw new Error();
        }
      } else if (ptcmDoc.status === ClientMapStatus.PRE_COMPLETE) {
        toast.error('School Letter Upload Not Allowed In Pre-Complete Stage!');
        return;
      }
    } else {
      throw new Error();
    }
  } catch (error) {
    toast.error('Error While Uploading!');
  }
};

export const approve154ELetter = async (clientId: any) => {
  try {
    if (!clientId) {
      throw new Error();
    }

    const querySnapshot = await queryFor154ELetter(clientId);

    if (!querySnapshot.empty) {
      const response: any = await httpsCallable(functions, 'approve154ELetter')({ id: querySnapshot.docs[0].id });
      if (response.data.success) {
        toast.success('School Letter Approved');
      }
    } else {
      throw new Error('Document not found!');
    }
  } catch (error: any) {
    toast.error('Error Approving School Letter!');
  }
};

export const getLiveClientInfoById = async (clientId: any, callback: Function) => {
  try {
    if (!clientId) {
      throw new Error();
    }

    const clientDocRef = doc(db, 'clients', clientId);

    const unsubscribe = onSnapshot(clientDocRef, async (snapshot) => {
      const letter154EDoc = await queryFor154ELetter(clientId);
      let letter154EData = {};

      if (!letter154EDoc.empty) {
        letter154EData = letter154EDoc.docs[0].data();
      }

      if (snapshot.exists()) {
        const clientData = { id: snapshot.id, ...letter154EData, ...snapshot.data() };
        callback(clientData);
      } else {
        callback({});
      }
    });

    return unsubscribe;
  } catch (error: any) {
    toast.error('Error Retrieving Client Data!');
    return () => {}; // Return a dummy function if an error occurs
  }
};

export const getClientInfoById = async (clientId: any) => {
  try {
    if (!clientId) {
      throw new Error();
    }

    const clientDocRef = doc(db, 'clients', clientId);
    const clientDoc = await getDoc(clientDocRef);
    const letter154EDoc = await queryFor154ELetter(clientId);
    let letter154EData = {};
    if (!letter154EDoc.empty) {
      letter154EData = letter154EDoc.docs[0].data();
    }
    if (clientDoc.exists()) {
      return { id: clientDoc.id, ...letter154EData, ...clientDoc.data() };
    } else {
      return {};
    }
  } catch (error: any) {
    toast.error('Error Retrieving Client Data!');
  }
};

export const addNewProspectiveClient = async (email: string, infoSessionId: string, userRole?: string) => {
  const hasPermission = shouldShowButton('inviteProspectiveClient', userRole);
  if (!hasPermission) {
    throw new Error('You do not have permission to invite Prospective Client');
  }
  const response = await httpsCallable(
    functions,
    'inviteProspectiveClient'
  )({ email: email, infoSessionId: infoSessionId });
  return response.data;
};

export const getClientListByNavigatorId = async (navigatorId: string, page: number) => {
  let result: ICLientMetaInfo = {
    totalRecords: 0,
    currentPage: page,
    clients: [],
    totalPages: 0,
  };

  try {
    const clientRef = collection(db, 'clients');
    const q = query(clientRef, where('assignedTo', '==', navigatorId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const clientList: any = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().account !== ClientAccount.DELETED) {
          clientList.push({ id: doc.id, ...doc.data() });
        }
      });
      const pageSize = 10;
      const offset = (page - 1) * pageSize;
      const totalPages = Math.ceil(clientList.length / pageSize);
      const limitedClients = clientList.slice(offset, offset + pageSize);
      result = {
        totalRecords: clientList.length,
        currentPage: page,
        clients: limitedClients,
        totalPages: totalPages,
      };
    }
    return result;
  } catch (err: any) {
    //catch errors
  }
};

export const assignNavigatorToClient = async (clientId: string, navigatorId: string) => {
  try {
    if (!clientId || !navigatorId) {
      throw new Error();
    }

    const payload = {
      id: clientId,
      navigatorId: navigatorId,
    };
    const response: any = await httpsCallable(functions, 'setNavigatorToClient')(payload);

    if (response.data.success) {
      toast.success('Navigator Assigned');
    }
  } catch (error: any) {
    toast.error('Error Assigning Navigator To Client!');
  }
};

export const suspendClient = async (clientId: string, suspensionReason: string, suspensionReasonId: number) => {
  try {
    if (!clientId || !suspensionReason || !suspensionReasonId) {
      throw new Error();
    }

    const payload = {
      id: clientId,
      suspensionReason: suspensionReason,
      suspensionReasonId: suspensionReasonId,
    };

    const response: any = await httpsCallable(functions, 'setClientSuspended')(payload);

    if (response.data.success) {
      toast.success('Client Suspended!');
    }
  } catch (error: any) {
    toast.error('Error: Unable To Suspend Client!');
  }
};
export const enrollClient = async (clientId: string, programTrackId: string) => {
  try {
    if (!clientId) {
      throw new Error('clientId is null or undefined');
    }
    const payload = {
      id: clientId,
      programTrackId: programTrackId,
    };
    const response: any = await httpsCallable(functions, 'setClientEnrolled')(payload);

    if (response.data.success) {
      toast.success('Client Enrolled!');
    }
  } catch (error: any) {
    toast.error('Error: Unable To Enroll Client!');
  }
};
export const cancelClientEnrollment = async (clientId: string) => {
  try {
    if (!clientId) {
      throw new Error();
    }

    const response: any = await httpsCallable(functions, 'cancelClientEnrollment')({ id: clientId });

    if (response.data.success) {
      toast.success('Enrollment Cancelled Successfully!');
    }
  } catch (error: any) {
    toast.error('Error: Unable To Cancel Client Enrollment!');
  }
};
export const cancelClientIndividualEnrollment = async (payload: ICancelIndividualEnrollment) => {
  try {
    const response: any = await httpsCallable(functions, 'setClientIndvidualEnrollmentCancel')(payload);

    if (response.data.success) {
      toast.success('Enrollment Cancelled Successfully!');
    }
  } catch (error: any) {
    toast.error('Error: Unable To Cancel Client Enrollment!');
  }
};

export const getUserInfoByUid = async (uid: string, isStaff: boolean) => {
  const clientsQuery = doc(db, 'clients', uid);
  const staffQuery = doc(db, 'staff', uid);

  if (!isStaff) {
    const docSnap = await getDoc(clientsQuery);
    return {
      id: docSnap.id,
      ...docSnap.data(),
    } as unknown as Promise<ICommonClient>;
  } else {
    const docSnap = await getDoc(staffQuery);
    return {
      id: docSnap.id,
      ...docSnap.data(),
    } as unknown as Promise<ICommonClient>;
  }
};

// filter clients on basis of db fields
export const getFilteredClientList = async (fieldName: string, fieldValue: any) => {
  try {
    if (!fieldName || !fieldValue) {
      throw new Error();
    }

    const clientsRef = collection(db, 'clients');
    const q = query(clientsRef, where(fieldName, '==', fieldValue));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const filteredClientList: any = [];
      querySnapshot.forEach((doc) => {
        filteredClientList.push({ id: doc.id, ...doc.data() });
      });
      return filteredClientList;
    } else {
      return [];
    }
  } catch (err: any) {
    toast.error('Error Retrieving Client Data!');
  }
};

export const getPendingEnrollmentClients = async () => {
  try {
    const clientsRef = collection(db, 'clients');
    const q = query(clientsRef, where('status', '==', ClientStatus.PENDING_ENROLLMENT));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const clientList: any = [];
      querySnapshot.forEach((doc) => {
        clientList.push({ id: doc.id, ...doc.data() });
      });
      return clientList;
    } else {
      return [];
    }
  } catch (err: any) {
    toast.error('Error Retrieving Client Data!');
  }
};

export const getClientListByUserId = async (userId: string) => {
  try {
    if (!userId) {
      throw new Error();
    }
    const clientRef = collection(db, 'clients');
    const q = query(clientRef, where('createdBy', '==', userId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const clientList: any = [];
      querySnapshot.forEach((doc) => {
        clientList.push({ id: doc.id, ...doc.data() });
      });
      return clientList;
    }
    return [];
  } catch (err: any) {
    console.log('Error Retrieving Client Data!');
  }
};

export const getClientsInfoByIds = async (clientIds: string[]) => {
  try {
    if (!clientIds.length) {
      throw new Error();
    }

    const clientRef = collection(db, 'clients');
    const querySnapshot = await getDocs(query(clientRef));
    const documents = querySnapshot.docs.filter((doc) => clientIds.includes(doc.id));

    const clientsList: any = [];

    documents.forEach((doc) => {
      clientsList.push({ id: doc.id, ...doc.data() });
    });

    return clientsList;
  } catch (err: any) {
    console.log('Error Retrieving Client Data!');
  }
};

export const addDocument = async (clientId: string, payload: IDocumentData, userRole: string) => {
  try {
    if (!clientId) {
      throw new Error();
    }
    if (
      userRole !== UserRolesEnum.ADMIN &&
      userRole !== UserRolesEnum.SUPER_ADMIN &&
      userRole !== UserRolesEnum.NAVIGATOR
    ) {
      throw new Error(`You do not have permission to upload clients documents`);
    }
    const clientRef = doc(db, 'clients', clientId);
    const clientSnapshot = await getDoc(clientRef);

    if (!clientSnapshot.exists()) {
      throw new Error(`Client does not exist.`);
    }

    const url: any = await uploadFiles([payload.file]);

    const newDocument = {
      id: clientId,
      name: payload.name,
      url: url[0],
      uploadedBy: payload.uploadedBy,
      role: payload.role,
      documentId: uuidv4(),
    };
    const response: any = await httpsCallable(functions, 'addDocument')(newDocument);
    if (response.data.success) {
      toast.success('Document Uploaded Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Uploading Document!');
  }
};

export const getClientDocuments = async (clientId: string) => {
  try {
    if (!clientId) {
      throw new Error();
    }
    const clientRef = doc(db, 'clients', clientId);
    const clientSnapshot = await getDoc(clientRef);

    if (clientSnapshot.exists()) {
      const currentDocuments = clientSnapshot.data()?.documents || [];
      return currentDocuments;
    } else {
      return [];
    }
  } catch (err: any) {
    toast.error('Error Fetching Documents!');
  }
};

export const deleteDocument = async (clientId: string, documentId: string, userRole: string) => {
  try {
    if (!clientId || !documentId) {
      throw new Error();
    }
    if (userRole !== UserRolesEnum.ADMIN && userRole !== UserRolesEnum.NAVIGATOR) {
      throw new Error(`You do not have permission to delete clients documents`);
    }

    const payload = {
      id: clientId,
      documentId: documentId,
    };

    const response: any = await httpsCallable(functions, 'deleteDocument')(payload);
    if (response.data.success) {
      toast.success('Document Deleted Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Deleting Document!');
  }
};

export const addNote = async (payload: INote) => {
  try {
    if (!payload || !payload.clientId) {
      throw new Error();
    }
    const newNote: any = {
      id: payload.clientId,
      title: payload.title,
      description: payload.description,
      createdBy: payload.createdBy,
      role: payload.role,
      noteId: uuidv4(),
    };
    if (payload.category) {
      newNote.category = payload.category;
    }
    const response: any = await httpsCallable(functions, 'createNote')(newNote);
    if (response.data.success) {
      toast.success('New Note Added Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Adding Note!');
  }
};

export const editNote = async (payload: IEditNote) => {
  try {
    if (!payload.clientId || !payload.noteId) {
      throw new Error();
    }
    const editNote: any = {
      id: payload.clientId,
      title: payload.title,
      description: payload.description,
      noteId: payload.noteId,
    };
    if (payload.category) {
      editNote.category = payload.category;
    }
    const response: any = await httpsCallable(functions, 'editNote')(editNote);
    if (response.data.success) {
      toast.success('Note Updated Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Updating Note!');
  }
};

export const deleteNote = async (clientId: string, noteId: string) => {
  try {
    if (!clientId || !noteId) {
      throw new Error();
    }
    const payload = {
      id: clientId,
      noteId: noteId,
    };
    const response: any = await httpsCallable(functions, 'deleteNote')(payload);
    if (response.data.success) {
      toast.success('Note Deleted Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Deleting Note!');
  }
};

export const getAllNotes = async (clientId: string, callback: Function) => {
  try {
    if (!clientId) {
      throw new Error();
    }
    const clientDocRef = doc(db, 'clients', clientId);
    const unsubscribe = onSnapshot(clientDocRef, async (querySnapshot) => {
      if (querySnapshot.exists()) {
        const notes = querySnapshot.data().notes;
        if (notes) {
          const creatorIds: string[] = [];
          notes.forEach((note: INote) => {
            creatorIds.push(note.createdBy);
          });
          const creatorInfoList = await getMultipleUserInfoByUserIds(creatorIds);
          for (const note of notes) {
            const creatorInfo: any = creatorInfoList.find((item: any) => item.id === note.createdBy);
            if (creatorInfo) {
              note.creatorFullName = creatorInfo.firstName + ' ' + creatorInfo.lastName;
            }
          }
          callback(notes);
        }
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    toast.error('Error Fetching Notes!');
  }
};

export const updateClientInterest = async (clientId: string, interestedIn: string) => {
  try {
    if (!clientId) {
      throw new Error();
    }

    const response: any = await httpsCallable(
      functions,
      'setClientInterest'
    )({ id: clientId, interestedIn: interestedIn });

    if (response.data.success) {
      toast.success('Interest Saved!');
    }
  } catch (error: any) {
    toast.error('Error Updating Client Ineterst!');
  }
};

export const isNavigatorAssignedToClient = async (navigatorId: string) => {
  try {
    if (!navigatorId) {
      throw new Error();
    }

    const collectionRef = collection(db, 'clients');
    const q = query(collectionRef, where('assignedTo', '==', navigatorId));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty ? true : false;
  } catch (err: any) {
    toast.error('Error Retrieving Data!');
  }
};

export const isEmpLiaisonAssignedToClient = async (liaisonId: string) => {
  try {
    if (!liaisonId) {
      throw new Error();
    }

    const collectionRef = collection(db, 'clients');
    const q = query(collectionRef, where('employmentLiaisonId', '==', liaisonId));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty ? true : false;
  } catch (err: any) {
    toast.error('Error Retrieving Data!');
  }
};

export const deleteClient = async (id: string) => {
  try {
    const response = await httpsCallable(functions, 'deleteClient')({ id: id });
    return response.data;
  } catch (error: any) {
    console.log('Error Deleting Client!');
  }
};

export const unenrollClient = async (id: string) => {
  try {
    const response = await httpsCallable(functions, 'unenrollClient')({ id: id });
    return response.data;
  } catch (error: any) {
    toast.error('Error Unenrolling Client!');
  }
};

export const getClientActivityLogsById = async (clientId: string, callback: Function) => {
  try {
    if (!clientId) {
      throw new Error('clientId is either null or undefined!');
    }
    const clientDocRef = doc(db, 'activityLogs', clientId);
    const unsubscribe = onSnapshot(clientDocRef, async (querySnapshot) => {
      const activityLogs: any = [];
      if (querySnapshot.exists()) {
        querySnapshot.data().activityLogs.forEach((item: any) => {
          activityLogs.push({
            timestamp: convertTimeStampToDate(item.timestamp).tz('America/New_York').format('YYYY-MM-DD hh:mm A'),
            context: item.text,
            realTime: item?.realTime || '',
            logId: item?.logId || '',
          });
        });
      }
      callback(activityLogs);
    });
    return unsubscribe;
  } catch (err: any) {
    toast.error('Error Retrieving Data!');
  }
  return [];
};

export const getAllClients = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'clients'));
    if (!querySnapshot.empty) {
      const clientList: any = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().account !== ClientAccount.DELETED) {
          clientList.push({ id: doc.id, ...doc.data() });
        }
      });
      return clientList;
    } else {
      return [];
    }
  } catch (error: any) {
    toast.error('Error Retrieving All Clients!');
  }
};

export const getClientsLive = (status: string, callback: Function) => {
  try {
    const collectionRef = collection(db, 'clients');
    const qry = query(collectionRef, where('status', '==', status));
    const unsubscribe = onSnapshot(qry, (querySnapshot) => {
      const list: any[] = [];
      for (const doc of querySnapshot.docs) {
        const docData = doc.data();
        const updatedDocData = { id: doc.id, ...docData };
        list.push(updatedDocData);
      }
      callback(list);
    });

    return unsubscribe;
  } catch (err: any) {
    return () => {};
  }
};

export const addNoteOnInterestUpdate = async (payload: INote) => {
  try {
    if (!payload || !payload.clientId) {
      throw new Error();
    }
    const newNote = {
      id: payload.clientId,
      title: payload.title,
      description: payload.description,
      createdBy: payload.createdBy,
      role: payload.role,
      noteId: uuidv4(),
    };
    await httpsCallable(functions, 'createNote')(newNote);
  } catch (err: any) {
    // err
  }
};

export const getClientsWithPendingSchoolLetter = async (trainerId: string) => {
  const collectionRef = collection(db, 'programTrackClientMap');
  const q = query(collectionRef, where('letter_154E', '==', null), where('trainerId', '==', trainerId));
  const querySnapshot = await getDocs(q);

  const clientIds: any = [];
  if (!querySnapshot.empty) {
    querySnapshot.forEach((doc) => {
      clientIds.push(doc.data().clientId);
    });

    const clientList = getClientsInfoByIds(clientIds);

    return clientList;
  } else {
    return [];
  }
};

export const registerProspectiveClient = async (payload: any) => {
  try {
    const response = await httpsCallable(functions, 'registerProspectiveClient')(payload);
    return response.data;
  } catch (error: any) {
    console.log('Error While Registering a Client!');
  }
};

export const assignEmploymentLiaison = async (clientId: string, employmentLiaisonId: string) => {
  try {
    const payload = { id: clientId, employmentLiaisonId: employmentLiaisonId };
    const response: any = await httpsCallable(functions, 'setEmpLiaisonToClient')(payload);
    if (response.data.success) {
      return true;
    } else {
      throw new Error();
    }
  } catch (error: any) {
    toast.error('Error While Assigning Employment Liaison To Client!');
  }
};

export const setWorkshopToClient = async (clientId: string, workshopId: string) => {
  try {
    const payload = { id: clientId, workshopId: workshopId };
    const response: any = await httpsCallable(functions, 'enrollClientInWorkshop')(payload);
    if (response.data.success) {
      return true;
    } else {
      throw new Error();
    }
  } catch (error: any) {
    toast.error('Error While Assigning Workshop To Client!');
  }
};

export const fetchClientCompletedWorkshop = async (callback: Function) => {
  try {
    const collectionRef = collection(db, 'clients');
    const unsubscribe = onSnapshot(collectionRef, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const clientIds: string[] = [];
        querySnapshot.docs.forEach((doc: any) => {
          if (
            doc.data().account !== userAccountStatus.DELETED &&
            doc.data().workshopAttendance &&
            doc.data().completedWorkshops
          ) {
            clientIds.push(doc.id);
          }
        });
        const clientList = await getMultipleClientByIds(clientIds);
        callback(clientList);
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Client Completed Workshop');
  }
};

export const checkIfEmailAlreadyExist = async (email: string) => {
  const collectionRef = collection(db, 'clients');
  const q = query(collectionRef, where('email', '==', email));
  const querySnapshot = await getDocs(q);
  let exists: boolean = false;
  if (!querySnapshot.empty) {
    exists = true;
  }
  return exists;
};

export const fetchPCForAttendanceRoster = async (infoSessionId: string, callback: Function) => {
  try {
    const collectionRef = collection(db, 'clients');
    const q = query(collectionRef, where('status', '==', ClientStatus.PROSPECTIVE));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const clientList: string[] = [];
        querySnapshot.docs.forEach((doc: any) => {
          if (
            doc.data().account !== userAccountStatus.DELETED &&
            doc.data().infoSessionIds &&
            !doc.data().infoSessionIds.includes(infoSessionId)
          ) {
            clientList.push({ id: doc.id, ...doc.data() });
          }
        });
        callback(clientList);
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Prospective Clients');
  }
};

export const markClientsWorkshopCompleted = async (payload: IClientJRTComplete) => {
  try {
    const response: any = await httpsCallable(functions, 'markClientsWorkshopCompleted')(payload);
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Marking Job Readiness Training Completed!');
    }
  } catch (error) {
    toast.error('Error While Marking Job Readiness Training Completed!');
  }
};

export const markJobInterviewAttendance = async (id: string, jobIds: string[]) => {
  try {
    const response: any = await httpsCallable(
      functions,
      'markClientsJobInterviewAttendance'
    )({ id: id, jobIds: jobIds });
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Marking Job Interview Attendance!');
    }
  } catch (error) {
    toast.error('Error While Marking Job Interview Attendance!');
  }
};

export const markClientReadyForEmployment = async (clientId: string) => {
  try {
    const response: any = await httpsCallable(functions, 'markClientReadyForEmployment')({ id: clientId });
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Marking Client Ready for Employment!');
    }
  } catch (error) {
    toast.error('Error While Marking Client Ready for Employment!');
  }
};

export const markEmploymentIntakeComplete = async (clientId: string, intakeStatus: string) => {
  try {
    const response: any = await httpsCallable(
      functions,
      'markClientEmploymentIntakeComplete'
    )({ id: clientId, intakeStatus: intakeStatus });
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Marking Client Employment Intake Complete!');
    }
  } catch (error) {
    toast.error('Error While Marking Client Employment Intake Complete!');
  }
};

export const convertClientToProspective = async (clientId: string) => {
  try {
    const response: any = await httpsCallable(functions, 'convertClientToProspective')({ id: clientId });
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Converting Client to Prospective!');
    }
  } catch (error) {
    toast.error('Error While Converting Client to Prospective!');
  }
};

export const getClientListByEmpLiaisonId = async (employmentLiaisonId: string, page: number, callback: Function) => {
  let result: ICLientMetaInfo = {
    totalRecords: 0,
    currentPage: page,
    clients: [],
    totalPages: 0,
  };
  try {
    const clientRef = collection(db, 'clients');
    const unsubscribe = onSnapshot(clientRef, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const clientList: any = [];
        querySnapshot.forEach((doc) => {
          if (
            doc.data().account !== ClientAccount.DELETED &&
            doc.data().employmentLiaisonId &&
            doc.data().employmentLiaisonId === employmentLiaisonId
          ) {
            clientList.push({ id: doc.id, ...doc.data() });
          }
        });
        const pageSize = 10;
        const offset = (page - 1) * pageSize;
        const totalPages = Math.ceil(clientList.length / pageSize);
        const limitedClients = clientList.slice(offset, offset + pageSize);
        result = {
          totalRecords: clientList.length,
          currentPage: page,
          clients: limitedClients,
          totalPages: totalPages,
        };
        callback(result);
      } else {
        callback(result);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    //catch errors
  }
};

export const getClientsEmploymentPageData = async (
  page: number,
  activeIds: number[],
  sortModel: ISortModel[],
  callback: Function
) => {
  let result: ICLientMetaInfo = {
    totalRecords: 0,
    currentPage: page,
    clients: [],
    totalPages: 0,
  };

  try {
    const clientRef = collection(db, 'clients');
    const unsubscribe = onSnapshot(clientRef, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const clientList: any = [];
        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          if (data.account !== ClientAccount.DELETED) {
            if (activeIds.includes(1) || activeIds.length < 1) {
              clientList.push({ id: doc.id, ...data });
            } else if (activeIds.includes(2)) {
              if (!data.employmentLiaisonId && data.employmentReadiness) {
                clientList.push({ id: doc.id, ...data });
              }
            } else if (activeIds.includes(3)) {
              if (
                data.employmentLiaisonId &&
                !data.employmentIntakeSubmitted &&
                !data.workshopId &&
                !data.employment &&
                !data.scheduledJobInterview
              ) {
                clientList.push({ id: doc.id, ...data });
              }
            } else if (activeIds.includes(4)) {
              if (
                data.employmentLiaisonId &&
                data.workshopId &&
                !data.completedWorkshops &&
                !data.scheduledJobInterview
              ) {
                clientList.push({ id: doc.id, ...data });
              }
            } else if (activeIds.includes(5)) {
              if (
                data.employmentLiaisonId &&
                data.workshopId &&
                data.completedWorkshops &&
                !data.scheduledJobInterview
              ) {
                clientList.push({ id: doc.id, ...data });
              }
            } else if (activeIds.includes(6)) {
              if (
                data.employmentLiaisonId &&
                data.workshopId &&
                data.completedWorkshops &&
                data.scheduledJobInterview &&
                !data.employment
              ) {
                clientList.push({ id: doc.id, ...data });
              }
            } else if (activeIds.includes(7)) {
              if (data.employmentLiaisonId && data.employment) {
                clientList.push({ id: doc.id, ...data });
              }
            }
          }
        }
        const lisisonIds: string[] = [];
        clientList.forEach((doc: any) => {
          if (doc.employmentLiaisonId) {
            lisisonIds.push(doc.employmentLiaisonId);
          }
        });
        const lisisonsInfoList = await getMultipleUserInfoByUserIds(lisisonIds);
        for (const client of clientList) {
          const reqLiaisonInfo: any = lisisonsInfoList.find((item: any) => item.id === client.employmentLiaisonId);
          client.employmentLiaisonName = reqLiaisonInfo ? reqLiaisonInfo.firstName + ' ' + reqLiaisonInfo.lastName : '';
        }

        if (sortModel.length > 0) {
          const field = sortModel[0].field;
          const sort = sortModel[0].sort;

          if (field === 'firstName') {
            clientList.sort((a: any, b: any) => {
              if (sort === 'asc') {
                return a.firstName.localeCompare(b.firstName);
              } else if (sort === 'desc') {
                return b.firstName.localeCompare(a.firstName);
              }
            });
          } else if (field === 'employmentLiaison') {
            clientList.sort((a: any, b: any) => {
              if (!a.employmentLiaisonName && !b.employmentLiaisonName) {
                return 0;
              } else if (!a.employmentLiaisonName) {
                return 1;
              } else if (!b.employmentLiaisonName) {
                return -1;
              } else {
                if (sort === 'asc') {
                  return a.employmentLiaisonName.localeCompare(b.employmentLiaisonName);
                } else if (sort === 'desc') {
                  return b.employmentLiaisonName.localeCompare(a.employmentLiaisonName);
                }
              }
            });
          } else if (field === 'interviewCount') {
            clientList.sort((a: any, b: any) => {
              const interviewCountA = a.scheduledJobInterview ? a.scheduledJobInterview.length : 0;
              const interviewCountB = b.scheduledJobInterview ? b.scheduledJobInterview.length : 0;

              if (sort === 'asc') {
                return interviewCountA - interviewCountB;
              } else if (sort === 'desc') {
                return interviewCountB - interviewCountA;
              }
              return 0; // Default case, should not happen
            });
          }
        }

        const pageSize = 10;
        const offset = (page - 1) * pageSize;
        const totalPages = Math.ceil(clientList.length / pageSize);
        const limitedClients = clientList.slice(offset, offset + pageSize);

        result = {
          totalRecords: clientList.length,
          currentPage: page,
          clients: limitedClients,
          totalPages: totalPages,
        };

        callback(result);
      } else {
        callback(result);
      }
    });

    return unsubscribe;
  } catch (err: any) {
    // handle errors
  }
};

export const getClientsEmploymentDataByFilter = async (
  page: number,
  filterOptions: { [key: string]: string[] },
  sortModel: ISortModel[],
  callback: Function
) => {
  let result: ICLientMetaInfo = {
    totalRecords: 0,
    currentPage: page,
    clients: [],
    totalPages: 0,
  };

  try {
    const clientRef = collection(db, 'clients');
    const unsubscribe = onSnapshot(clientRef, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        let clientList: any = [];
        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          if (data.account !== ClientAccount.DELETED) {
            clientList.push({ id: doc.id, ...data });
            if (filterOptions.employmentModuleStatus && filterOptions.employmentModuleStatus.length > 0) {
              for (const option of filterOptions.employmentModuleStatus) {
                if (option === '1') {
                  if (!data.employmentLiaisonId) {
                    clientList.push({ id: doc.id, ...data });
                  }
                } else if (option === '2') {
                  if (
                    data.employmentLiaisonId &&
                    !data.employmentIntakeSubmitted &&
                    !data.workshopId &&
                    !data.employment
                  ) {
                    clientList.push({ id: doc.id, ...data });
                  }
                } else if (option === '3') {
                  if (
                    data.employmentLiaisonId &&
                    data.workshopId &&
                    !data.completedWorkshops &&
                    !data.scheduledJobInterview &&
                    !data.employment
                  ) {
                    clientList.push({ id: doc.id, ...data });
                  }
                } else if (option === '4') {
                  if (
                    data.employmentLiaisonId &&
                    data.workshopId &&
                    data.completedWorkshops &&
                    !data.scheduledJobInterview &&
                    !data.employment
                  ) {
                    clientList.push({ id: doc.id, ...data });
                  }
                } else if (option === '5') {
                  if (data.employmentLiaisonId && data.scheduledJobInterview && !data.employment) {
                    clientList.push({ id: doc.id, ...data });
                  }
                } else if (option === '6') {
                  if (data.employmentLiaisonId && data.scheduledJobInterview && data.employment) {
                    clientList.push({ id: doc.id, ...data });
                  }
                }
              }
            }
            const processedFilters: { [key: string]: string[] } = {};
            if (filterOptions.employmentStatus) {
              processedFilters.employmentStatus = mapIdsToValues(filterOptions.employmentStatus, empStatusOptions);
            }
            if (filterOptions.employmentType) {
              processedFilters.employmentType = mapIdsToValues(filterOptions.employmentType, employmentTypes);
            }

            const doesClientMatchFilters = (client: any, filters: { [key: string]: string[] }) => {
              return Object.entries(filters).every(([filterKey, filterValues]) => {
                if (!filterValues || filterValues.length === 0) return true;

                return filterValues.some((value) => {
                  const clientValue = client[filterKey];
                  if (Array.isArray(clientValue)) {
                    return clientValue.includes(value);
                  }
                  return clientValue === value;
                });
              });
            };

            clientList = clientList.filter((client: any) => doesClientMatchFilters(client, processedFilters));
          }
        }

        const lisisonIds: string[] = [];
        clientList.forEach((doc: any) => {
          if (doc.employmentLiaisonId) {
            lisisonIds.push(doc.employmentLiaisonId);
          }
        });
        const lisisonsInfoList = await getMultipleUserInfoByUserIds(lisisonIds);
        for (const client of clientList) {
          const reqLiaisonInfo: any = lisisonsInfoList.find((item: any) => item.id === client.employmentLiaisonId);
          client.employmentLiaisonName = reqLiaisonInfo ? reqLiaisonInfo.firstName + ' ' + reqLiaisonInfo.lastName : '';
        }

        if (sortModel.length > 0) {
          const field = sortModel[0].field;
          const sort = sortModel[0].sort;

          if (field === 'firstName') {
            clientList.sort((a: any, b: any) => {
              if (sort === 'asc') {
                return a.firstName.localeCompare(b.firstName);
              } else if (sort === 'desc') {
                return b.firstName.localeCompare(a.firstName);
              }
            });
          } else if (field === 'employmentLiaison') {
            clientList.sort((a: any, b: any) => {
              if (!a.employmentLiaisonName && !b.employmentLiaisonName) {
                return 0;
              } else if (!a.employmentLiaisonName) {
                return 1;
              } else if (!b.employmentLiaisonName) {
                return -1;
              } else {
                if (sort === 'asc') {
                  return a.employmentLiaisonName.localeCompare(b.employmentLiaisonName);
                } else if (sort === 'desc') {
                  return b.employmentLiaisonName.localeCompare(a.employmentLiaisonName);
                }
              }
            });
          } else if (field === 'interviewCount') {
            clientList.sort((a: any, b: any) => {
              const interviewCountA = a.scheduledJobInterview ? a.scheduledJobInterview.length : 0;
              const interviewCountB = b.scheduledJobInterview ? b.scheduledJobInterview.length : 0;

              if (sort === 'asc') {
                return interviewCountA - interviewCountB;
              } else if (sort === 'desc') {
                return interviewCountB - interviewCountA;
              }
              return 0; // Default case, should not happen
            });
          }
        }

        const pageSize = 10;
        const offset = (page - 1) * pageSize;
        const totalPages = Math.ceil(clientList.length / pageSize);
        const limitedClients = clientList.slice(offset, offset + pageSize);

        result = {
          totalRecords: clientList.length,
          currentPage: page,
          clients: limitedClients,
          totalPages: totalPages,
        };

        callback(result);
      } else {
        callback(result);
      }
    });

    return unsubscribe;
  } catch (err: any) {
    // handle errors
  }
};

export const searchClientsEmployment = async (searchQuery: string, searchQueryType: string, page: number) => {
  try {
    let result: ICLientMetaInfo = {
      totalRecords: 0,
      currentPage: page,
      clients: [],
      totalPages: 0,
    };

    if (searchQuery) {
      if (searchQuery.includes('@')) {
        searchQuery = searchQuery.split('@')[0];
      }
      const response: any = await httpsCallable(
        functions,
        'searchClientByName'
      )({ query: searchQuery, type: searchQueryType });

      const clientData = response.data.data;
      const clientIds: any = [];
      let clientList = [];

      if (clientData.length > 0) {
        for (const data of clientData) {
          clientIds.push(data.id);
        }

        clientList = await getMultipleClientByIds(clientIds);

        // Fetch employmentLiaisonName for each client concurrently
        const getUserFullNamePromises = clientList.map(async (client: any) => {
          if (client.employmentLiaisonId) {
            client.employmentLiaisonName = await getUserFullNameById(client.employmentLiaisonId);
          }
        });

        await Promise.all(getUserFullNamePromises);

        const pageSize = 10;
        const offset = (page - 1) * pageSize;
        const totalPages = Math.ceil(clientList.length / pageSize);
        const limitedClients = clientList.slice(offset, offset + pageSize);

        result = {
          totalRecords: clientList.length,
          currentPage: page,
          clients: limitedClients,
          totalPages: totalPages,
        };
      } else {
        return result;
      }
    }

    return result;
  } catch (error) {
    // handle errors
  }
};

export const sendTextMessage = async (clientIds: string[], text: string) => {
  try {
    const payload: ISendTextMessage = {
      ids: clientIds,
      text: text,
    };
    const response: any = await httpsCallable(functions, 'SendTextMessageToClients')(payload);
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Text Message!');
    }
  } catch (error) {
    toast.error('Error While Text Message!');
  }
};

export const updateClientsEmplInfo = async (payload: IClientEmplInfo) => {
  try {
    const response: any = await httpsCallable(functions, 'updateClientsEmploymentInfo')(payload);
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Updating Clinets Employment Info!');
    }
  } catch (error) {
    toast.error('Error While Updating Clinets Employment Info!');
  }
};
export const addRealTimeInActivityLogs = async (logId: string, clientId: string, realTime: string) => {
  try {
    const response: any = await httpsCallable(functions, 'addRealTimeInActivityLogs')({ logId, clientId, realTime });
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Creating or Updating Real Time!');
    }
  } catch (error) {
    toast.error('Error While Creating or Updating Real Time!');
  }
};

export const fetchClientsForTextMessaging = async (user: UserModel) => {
  try {
    const querySnapshot = await getDocs(collection(db, 'clients'));
    if (!querySnapshot.empty) {
      const clientList: any = [];
      querySnapshot.forEach((doc) => {
        clientList.push({ id: doc.id, ...doc.data() });
      });
      if (user.role === UserRolesEnum.NAVIGATOR) {
        return clientList.filter((client: any) => client.assignedTo === user?.id);
      } else if (user.role === UserRolesEnum.EMPLOYMENT_LIAISON) {
        return clientList.filter((client: any) => client.employmentLiaisonId === user?.id);
      } else {
        return clientList;
      }
    } else {
      return [];
    }
  } catch (err: any) {
    // console err
  }
  return [];
};

export const toggleClientStatus = async (id: string, activeStatus: string) => {
  try {
    await httpsCallable(functions, 'toggleClientActiveStatus')({ id, activeStatus });
  } catch (error) {
    toast.error('Error While Creating or Updating Real Time!');
  }
};

export const listClientByFiltersApplied = async (
  page: number,
  filterOptions: { [key: string]: string[] },
  sortModel: ISortModel[]
) => {
  try {
    const clientSnapshot = await getDocs(collection(db, 'clients'));
    if (clientSnapshot.empty) {
      return [];
    }
    const allClients: any[] = [];
    clientSnapshot.docs.forEach((doc) => {
      allClients.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    const clientList = allClients.filter((obj: any) => obj?.account !== ClientAccount.DELETED);

    const processedFilters: { [key: string]: string[] } = {};

    if (filterOptions.status) {
      processedFilters.status = mapIdsToValues(filterOptions.status, statusOptions);
    }
    if (filterOptions.gender) {
      processedFilters.gender = mapIdsToValues(filterOptions.gender, genderOptions);
    }
    if (filterOptions.ethnic) {
      processedFilters.ethnic = mapIdsToValues(filterOptions.ethnic, ethnicityOptions);
    }
    if (filterOptions.highestDL) {
      processedFilters.highestDL = mapIdsToValues(filterOptions.highestDL, highestDLOptions);
    }
    if (filterOptions.educationalBackground) {
      processedFilters.educationalBackground = mapIdsToValues(filterOptions.educationalBackground, educationOptions);
    }
    if (filterOptions.referralSource) {
      processedFilters.referralSource = mapIdsToValues(filterOptions.referralSource, referralSourceOptions);
    }
    if (filterOptions.publicAssistance) {
      processedFilters.publicAssistance = mapIdsToValues(filterOptions.publicAssistance, publicAssistanceOptions);
    }
    if (filterOptions.activeStatus) {
      processedFilters.activeStatus = mapIdsToValues(filterOptions.activeStatus, activeStatusOptions);
    }
    if (filterOptions.employmentStatus) {
      processedFilters.employmentStatus = mapIdsToValues(filterOptions.employmentStatus, empStatusOptions);
    }
    if (filterOptions.employmentType) {
      processedFilters.employmentType = mapIdsToValues(filterOptions.employmentType, employmentTypes);
    }
    if (filterOptions.interestedIn) {
      processedFilters.interestedIn = filterOptions.interestedIn;
    }
    if (filterOptions.assignedTo) {
      processedFilters.assignedTo = filterOptions.assignedTo;
    }

    const doesClientMatchFilters = (client: any, filters: { [key: string]: string[] }) => {
      return Object.entries(filters).every(([filterKey, filterValues]) => {
        if (!filterValues || filterValues.length === 0) return true;

        return filterValues.some((value) => {
          const clientValue = client[filterKey];
          if (Array.isArray(clientValue)) {
            return clientValue.includes(value);
          }
          return clientValue === value;
        });
      });
    };

    let filteredClient = clientList.filter((client: any) => doesClientMatchFilters(client, processedFilters));

    if (filterOptions.programTrack && filterOptions.programTrack.length > 0) {
      const q = query(
        collection(db, 'programTrackClientMap'),
        where('programTrackId', 'in', filterOptions.programTrack)
      );
      const querySnapshot = await getDocs(q);
      const clientIds = querySnapshot.docs.map((doc) => {
        return doc.data().clientId;
      });
      filteredClient = filteredClient.filter((client: any) => clientIds.includes(client.id));
    }

    const navigatorInfoIds = new Set<string>();
    const clientMapDataIds = new Set<string>();
    const careerPathIds = new Set<string>();

    filteredClient.forEach((client: any) => {
      if (client.assignedTo) navigatorInfoIds.add(client.assignedTo);
      if (client.id) clientMapDataIds.add(client.id);
      if (client.interestedIn) careerPathIds.add(client.interestedIn);
    });
    // Convert Set to Array before spreading
    const [navigatorInfo, careerPathInfo, clientMapData] = await Promise.all([
      getMultipleUserInfoByUserIds(Array.from(navigatorInfoIds)),
      getMultipleCareerPathInfoByIds(Array.from(careerPathIds)),
      getClientMapDataByMultipleClientId(Array.from(clientMapDataIds)),
    ]);

    const clientMapDataById = clientMapData.reduce((acc: any, data: any) => {
      if (!acc[data.clientId] || acc[data.clientId].enrollInitiatedAt < data.enrollInitiatedAt) {
        acc[data.clientId] = data;
      }
      return acc;
    }, {});

    const programTrackInfoIds = new Set<string>();

    filteredClient.forEach((client: any) => {
      client.navigatorInfo = navigatorInfo.find((data: any) => data.id === client.assignedTo) || '';
      client.careerPathInfo = careerPathInfo.find((data: any) => data.id === client.interestedIn) || '';
      client.clientMapData = clientMapDataById[client.id] || '';
      if (client.clientMapData && client.clientMapData.programTrackId) {
        programTrackInfoIds.add(client.clientMapData.programTrackId);
      }
    });

    // Convert Set to Array before spreading
    const programTrackInfo = await getMultipleProgramTracksById(Array.from(programTrackInfoIds));

    const programTrackInfoById = programTrackInfo.reduce((acc: any, data: any) => {
      acc[data.id] = data;
      return acc;
    }, {});

    filteredClient.forEach((client: any) => {
      client.programTrackInfo = programTrackInfoById[client.clientMapData.programTrackId] || '';
    });

    if (sortModel.length > 0) {
      const field = sortModel[0].field;
      const sort = sortModel[0].sort;

      if (field === 'client') {
        filteredClient.sort((a: any, b: any) => {
          if (sort === 'asc') {
            return a.firstName.localeCompare(b.firstName);
          } else if (sort === 'desc') {
            return b.firstName.localeCompare(a.firstName);
          }
        });
      } else if (field === 'clientStatus') {
        filteredClient.sort((a: any, b: any) => {
          if (sort === 'asc') {
            return a.status.localeCompare(b.status);
          } else if (sort === 'desc') {
            return b.status.localeCompare(a.status);
          }
        });
      } else if (field === 'navigator') {
        filteredClient.sort((a: any, b: any) => {
          if (!a.navigatorInfo && !b.navigatorInfo) {
            return 0;
          } else if (!a.navigatorInfo) {
            return 1;
          } else if (!b.navigatorInfo) {
            return -1;
          } else {
            if (sort === 'asc') {
              return a.navigatorInfo.firstName.localeCompare(b.navigatorInfo.firstName);
            } else if (sort === 'desc') {
              return b.navigatorInfo.firstName.localeCompare(a.navigatorInfo.firstName);
            }
          }
        });
      } else if (field === 'programTrack') {
        filteredClient.sort((a: any, b: any) => {
          if (!a.programTrackInfo && !b.programTrackInfo) {
            return 0;
          } else if (!a.programTrackInfo) {
            return 1;
          } else if (!b.programTrackInfo) {
            return -1;
          } else {
            if (sort === 'asc') {
              return a.programTrackInfo.name.localeCompare(b.programTrackInfo.name);
            } else if (sort === 'desc') {
              return b.programTrackInfo.name.localeCompare(a.programTrackInfo.name);
            }
          }
        });
      } else if (field === 'interestedInData') {
        filteredClient.sort((a: any, b: any) => {
          if (!a.careerPathInfo && !b.careerPathInfo) {
            return 0;
          } else if (!a.careerPathInfo) {
            return 1;
          } else if (!b.careerPathInfo) {
            return -1;
          } else {
            if (sort === 'asc') {
              return a.careerPathInfo.name.localeCompare(b.careerPathInfo.name);
            } else if (sort === 'desc') {
              return b.careerPathInfo.name.localeCompare(a.careerPathInfo.name);
            }
          }
        });
      }
    } else {
      filteredClient.sort((a: any, b: any) => {
        const dateA = a.createdAt ? convertTimeStampToDate(a.createdAt).toDate() : null;
        const dateB = b.createdAt ? convertTimeStampToDate(b.createdAt).toDate() : null;

        if (dateA && dateB) {
          return dateB.getTime() - dateA.getTime();
        } else if (dateB) {
          return 1; // Place documents without 'createdAt' field after those with 'createdAt'
        } else if (dateA) {
          return -1; // Place documents without 'createdAt' field before those with 'createdAt'
        } else {
          return 0; // Both documents don't have 'createdAt' field, no preference in order
        }
      });
    }

    const pageSize = 10;
    const offset = (page - 1) * pageSize;

    const totalPages = Math.ceil(filteredClient.length / pageSize);
    const limitedClients = filteredClient.slice(offset, offset + pageSize);
    const result: ICLientMetaInfo = {
      totalRecords: filteredClient.length,
      currentPage: page,
      clients: limitedClients,
      totalPages: totalPages,
    };
    return result;
  } catch (err: any) {
    toast.error('Error Retrieving Client Data!');
  }
};
